<template>
  <section class="subscribeForm">
    <img src="https://alpha.owlweb.com.ua/images/first_bg.png" alt="" />
    <div class="form">
      <p class="title">{{ globalSetting.blog_subscribe_title }}</p>
      <p class="desc">
        {{ globalSetting.blog_subscribe_text }}
      </p>
      <form @submit.prevent="formSubmit" :class="{ success: isSuccess }">
        <input
          type="email"
          class="formInput"
          v-model="email"
          :placeholder="globalSetting.blog_subscribe_input_placeholder"
        />
        <button type="submit" class="submit">
          {{ globalSetting.blog_subscribe_btn_name }} <i class="icon ic-arrow"></i>
        </button>
        <span class="error" v-if="isSuccess === false">error</span>
      </form>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      email: null,
      isSuccess: null
    };
  },
  computed: {
    ...mapGetters(["globalSetting"])
  },
  methods: {
    async formSubmit() {
      try {
        const res = await this.axios.post("/api/news/subscribe", {
          email: this.email
        });
        this.isSuccess = res.data.success;
      } catch (err) {
        this.isSuccess = false;
      }
    }
  },
  watch: {
    isSuccess(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.isSuccess = null;
        }, 2000);
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.subscribeForm
    position: relative
    img
        width: 70%
        margin-left: auto
        @media (max-width: 576px)
            width: 100%
    .form
        background-color: $c-gray
        max-width: 950px
        position: absolute
        bottom: 0px
        left: 0px
        padding: 89px 200px
        color: #fff
        @media (max-width: 1024px)
            padding: 50px
            max-width: 650px
        @media (max-width: 768px)
            max-width: 491px
            padding: 25px
        @media (max-width: 576px)
            position: static
        .title
            font-weight: 500
            font-size: 25px
            line-height: 140%
            margin-bottom: 20px
        .desc
            margin-bottom: 35px
            font-weight: 300
            font-size: 17px
            line-height: 150%
        form
            display: flex
            justify-content: space-between
            &::before
                transition: .3s
            &.success
                position: relative
                &::before
                    content: 'Success'
                    text-align: center
                    font-weight: 600
                    line-height: 63px
                    position: absolute
                    width: 100%
                    height: 100%
                    background-color: #6CC39F

            @media (max-width: 576px)
                display: flex
                flex-direction: column
                .formInput
                    width: 100% !important
                    margin-bottom: 10px
            .formInput
                height: 63px
                padding: 0px 16px
                border: none
                width: 100%
            .submit
                height: 63px
                background-color: $c-btn
                border: none
                color: #fff
                padding: 0px 22px
                font-weight: 600
                font-size: 18px
                width: 50%
                i
                    font-size: 14px
                    margin-left: 9px
        .error
            color: red
</style>
